import { render, staticRenderFns } from "./mn4G-BMG2.vue?vue&type=template&id=65f89335&scoped=true&"
import script from "./mn4G-BMG2.vue?vue&type=script&lang=js&"
export * from "./mn4G-BMG2.vue?vue&type=script&lang=js&"
import style0 from "./mn4G-BMG2.vue?vue&type=style&index=0&id=65f89335&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f89335",
  null
  
)

export default component.exports