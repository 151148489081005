<template>
  <div class="home">
    <div class="demo-image__lazy">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_09.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_11.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_12.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BMG2/images/MG2_13.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "低功耗4G-BMG2",
  components: {
    elMenu, foot, navigation
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.demo-image__lazy{
  display: block;
  min-height: 100px;
  margin-bottom: 10px;
}
</style>